import * as React from "react"
import {useContext, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {Link} from "gatsby";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'

const ContactSchema = Yup.object().shape({
    name: Yup.string().required('Il nome è obbligatorio'),
    surname: Yup.string().required('Il cognome è obbligatorio'),
    phone: Yup.number('Solo caratteri numerici').required('Il numero di telefono è obbligatorio'),
    email: Yup.string().email('L\'indirizzo email digitato non è valido').required('L\'indirizzo email è obbligatorio'),
    message: Yup.string().required('Il nome è obbligatorio'),
    privacy: Yup.bool().oneOf([true])
});


const FormContact = ({onSent, pageContext}) => {
    const [loading, setLoading] = useState(false)
    const onlyNumbers = e => {
        if (e.which >= 48 && e.which <= 57) return true
        e.preventDefault()
    }
    const gContext = useContext(GlobalContext);
    const gtag_report_conversion = (url) => {
        var callback = function () {};
        if(window.gtag) {
            window.gtag('event', 'conversion', {
                'send_to': 'AW-978530713/wsMcCMrwmPsBEJnjzNID',
                'event_callback': callback
            });
        }
        return false;
    }

    return (
        <Formik
            initialValues={{
                name: '',
                surname: '',
                phone: '',
                email: '',
                message: '',
                privacy: false,
            }}
            validationSchema={ContactSchema}
            onSubmit={(values, {resetForm}) => {
                setLoading(true)
                gtag_report_conversion()
                values.template = 'contacts'
                axios.post(process.env.GATSBY_API_URL + '/api/send/mail', values, { withCredentials: true })
                    .then(res => {
                        onSent(true)
                        resetForm();
                    }).catch(() => {

                    }).finally(() => {
                        setLoading(false)
                    })
            }}
        >
            {({ values, errors, touched }) => (
                <Form>
                    <div className="flex flex-col space-y-2">
                        <div className="input-outline-float">
                            <Field id="name" name="name" className={`${errors.name && touched.name ? '!text-red-500 !border-red-500' : 'bg-transparent text-black'}`} />
                            <label for="name" className={`${errors.name && touched.name ? '!text-red-500' : 'text-black'}`}>{gContext.t('Nome')}</label>
                        </div>
                        <div className="input-outline-float">
                            <Field id="surname" name="surname"   className={`${errors.surname && touched.surname ? '!text-red-500 !border-red-500' : 'bg-transparent text-black'}`} />
                            <label for="surname" className={`${errors.surname && touched.surname ? '!text-red-500' : 'text-black'}`}>{gContext.t('Cognome')}</label>
                        </div>
                        <div className="input-outline-float">
                            <Field onKeyPress={onlyNumbers}  maxlength="11" id="phone" name="phone" className={`${errors.phone && touched.phone ? '!text-red-500 !border-red-500' : 'bg-transparent text-black'}`} />
                            <label for="phone" className={`${errors.phone && touched.phone ? '!text-red-500' : 'text-black'}`}>{gContext.t('Telefono')}</label>
                        </div>
                        <div className="input-outline-float">
                            <Field id="email" name="email" className={`${errors.email && touched.email ? '!text-red-500 !border-red-500' : 'bg-transparent text-black'}`}/>
                            <label for="email" className={`${errors.email && touched.email ? '!text-red-500' : 'text-black'}`}>{gContext.t('Email')}</label>
                        </div>
                        <div className="input-outline-float">
                            <Field as="textarea" id="message" name="message"
                                   className={`${errors.message && touched.message ? '!text-red-500 !border-red-500' : 'bg-transparent text-black'}`}/>
                            <label for="message"
                                   className={`${errors.message && touched.message ? '!text-red-500' : 'text-black'}`}>{gContext.t('Messaggio')}</label>
                        </div>
                    </div>

                    <div className="mt-6 flex items-center">
                        <div className="mb-4 md:mb-0 mt-2 md:mt-0 relative flex items-start pr-4 flex-1 self-start md:self-auto">
                            <div className="flex items-center h-6">
                                <Field id="privacy" aria-describedby="privacy" name="privacy" type="checkbox" className={`h-5 w-5 focus:ring-0 rounded-md cursor-pointer ${(!values.privacy && touched.privacy && errors.privacy) ? 'error-check' : 'border text-dark border-grayinput'}`}/>
                            </div>
                            <div className="ml-2 sm:ml-3 text-sm sm:text-base text-grayinput font-extralight">
                                <span dangerouslySetInnerHTML={{ __html: gContext.t('form.privacy') }}></span>
                                <Link to={gContext.url('privacy')} className="ml-1 hover:underline focus:outline-none focus:ring-0 bg-transparent">{gContext.t('Privacy')}</Link>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex flex-col items-center justify-center">
                        <button type="submit" disabled={loading} className="inline-flex items-center justify-center text-center text-base uppercase font-semibold min-h-[50px] py-2 px-3 w-full rounded-md bg-gray-700 hover:bg-primary text-black focus:outline-none focus:ring-0 transition duration-200 ease-in-out">
                            {loading ? (
                                <svg className="animate-spin h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg"
                                     fill="none"
                                     viewBox="0 0 24 24"
                                >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : gContext.t('Invia richiesta')}
                        </button>
                        {/* TODO: si vede con uno o più errori */}
                        {/*<button type="button" disabled={loading} className="inline-flex items-center justify-center text-center text-base uppercase font-semibold min-h-[50px] py-2 px-3 w-full rounded-md bg-red-600 hover:bg-red-700 text-white focus:outline-none focus:ring-0 transition duration-200 ease-in-out">*/}
                        {/*    {gContext.t('Errori nei campi ricontrolla il form')}*/}
                        {/*</button>*/}
                    </div>

                </Form>
            )}
            </Formik>
    )
}

export default FormContact
